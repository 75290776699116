<template>
  <v-container>
    <link
      href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      rel="stylesheet"
      integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
      crossorigin="anonymous"
    />
    <Loading :loadingCounter="loadingCounter" />
    <v-row justify="end" align="center">
      <v-img
        id="company-logo"
        alt="company Logo"
        class="shrink mr-2"
        contain
        src="../assets/img/nikkon_logo.png"
        transition="scale-transition"
        width="50"
      />
      <v-img
        id="company-name-logo"
        alt="company Name Logo"
        class="shrink mr-2"
        contain
        src="../assets/img/nikkon_name_logo.png"
        transition="scale-transition"
        width="250"
      />
    </v-row>
    <v-row justify="center" align-content="center">
      <v-col>
        <v-card id="login-form" class="login-form-img">
          <div id="form-layout">
            <div id="card-title">
              <v-row justify="center" align="center">
                <v-img
                  id="system-logo"
                  alt="system Logo"
                  class="shrink mr-2"
                  contain
                  src="../assets/img/cirrus_logo.png"
                  transition="scale-transition"
                  width="300"
                />
              </v-row>
            </div>
          </div>
        </v-card>
        <v-card id="login-form" class="login-form-login" style="margin: 10rem auto 0rem auto">
          <div id="form-layout">
            <v-form id="input-area" ref="loginForm" v-model="validationResult">
              <p class="text-left">ユーザーID</p>
              <v-text-field
                id="txt-user-id"
                class="input-text-fielld"
                placeholder="ユーザーID"
                v-model="userId"
                :rules="[rules.required, rules.userLength]"
                maxlength="20"
                @input="formValidation"
                autoComplete="login-username"
              ></v-text-field>
              <p class="text-left">パスワード</p>
              <v-text-field
                id="txt-password"
                class="input-text-fielld"
                placeholder="パスワード"
                v-model="userPassword"
                :type="inputType"
                :rules="[rules.required, rules.passwordLength]"
                maxlength="20"
                @input="formValidation"
                autoComplete="login-current-password"
              ></v-text-field>
              <span class="input-icon">
                <span :class="iconType" @click="onClick"></span>
              </span>
              <!-- <div style="text-align: left; margin: 14px 0 20px 0">
                <input
                  type="checkbox"
                  v-model="choise"
                  style="transform: scale(2); margin-left: 7px"
                  @change="checkList(item, index)"
                />
              </div> -->
              <v-btn id="btn-login" @click="login('office')" :disabled="!validationResult"
                >ログイン</v-btn
              >
            </v-form>
          </div>
          <div style="text-align: right; font-size: 0.6em">Version:{{ getVersion() }}</div>
        </v-card>
        <v-card
          id="login-form"
          class="login-form-img"
          style="margin: 2rem auto 0rem auto !important"
        >
          <div style="text-align: center !important">
            <b class="text-left" style="margin-left: 15px; color: blue"
              >お客様はこちらからログインしてください</b
            >
          </div>
          <div style="text-align: center">
            <v-btn
              id="btn-login"
              @click="login('shipper')"
              :disabled="!validationResult"
              style="margin: 0 1rem 0.5rem 1rem"
              >お客様用ログイン</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- ダイアログ -->
    <v-dialog v-model="resultDialog.isOpen" :max-width="800">
      <v-card>
        <v-card-title class="blue-grey lighten-3" primary-title>
          {{ resultDialog.title }}
        </v-card-title>
        <v-card-text class="pa-4">
          <p class="dialig-text">{{ resultDialog.message }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
// import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { appConfig } from "../assets/scripts/js/AppConfig";
import { appCertification } from "../assets/scripts/js/AppCertification";
import Loading from "@/components/loading";
// import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper";

import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: appCertification.DATADOG.DD_APPLICATION_ID,
  clientToken: appCertification.DATADOG.DD_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: appCertification.DATADOG.DD_SERVICE,
  env: appCertification.DATADOG.DD_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: appCertification.DATADOG.DD_SESSION,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

export default {
  name: "Login",
  components: {
    Loading,
  },
  data: () => ({
    isChecked: false,
    loadingCounter: 0,
    userId: "",
    userPassword: "",
    validationResult: false,
    resultDialog: { isOpen: false, message: "", title: "" },
    roleId: "",
    rules: {
      required: (value) =>
        !!value || messsageUtil.getMessage("P-COM-001_002_E", ["ユーザー名", "パスワード"]),
      // maxLengthがあるので実質動作しない。
      userLength: (value) =>
        (value && value.length >= 1 && value.length <= 20) ||
        1 + "～" + 20 + "文字で入力してください",
      // maxLengthがあるので実質動作しない。
      passwordLength: (value) =>
        (value && value.length >= 8 && value.length <= 20) ||
        8 + "～" + 20 + "文字で入力してください",
    },
  }),
  created() {
    // 御製ログアウトになって場合、警告メッセージとcognitoデータ削除
    var lang = sessionStorage.getItem("lang");
    if (sessionStorage.getItem("usercode") != null) {
      this.$httpClient.logout(sessionStorage.getItem("usercode"));
      const message = messsageUtil.getMessage("P-COM-001_003_W");
      this.resultDialog.message = message;
      this.resultDialog.isOpen = true;
      this.resultDialog.title = appConfig.DIALOG.title;
    }
    sessionStorage.clear();
    sessionStorage.setItem("lang", lang);
  },
  mounted() {
    console.log("lang", sessionStorage.getItem("lang"));
    if (sessionStorage.getItem("lang")) {
      const language = window.navigator.language;

      let locale = "en";
      if (language) {
        const lang = language.toLowerCase().split("-");
        switch (lang[0]) {
          case "ja": // 日本語: ja, ja-JP
            locale = lang[0];
            break;
          case "es": // スペイン語: es, es-*
            locale = lang[0];
            break;
          case "ko": // 韓国語
            locale = lang[0];
            break;
          case "zhcn": // 中国語 (簡体)
            locale = lang[0];
            break;
          case "zhtw": // 中国語 (繁体)
            locale = lang[0];
            break;

          case "zh": // 中国語: zh-*
            switch (lang[1]) {
              case "cn": // 中国
                locale = "zhcn"; // 中国語 (簡体)
                break;
              case "sg": // シンガポール
                locale = "zhcn"; // 中国語 (簡体)
                break;
              case "hans":
                locale = "zhcn"; // 中国語 (簡体)
                break;
              case "tw": // 台湾
                locale = "zhtw"; // 中国語 (繁体)
                break;
              case "hk": // 香港
                locale = "zhtw"; // 中国語 (繁体)
                break;
              case "mo": // マカオ
                locale = "zhtw"; // 中国語 (繁体)
                break;
              case "hant":
                locale = "zhtw"; // 中国語 (繁体)
                break;
            }

            break;
        }
      }

      sessionStorage.setItem("lang", locale);
    }
  },
  methods: {
    onClick() {
      this.isChecked = !this.isChecked;
    },
    test() {
      sessionStorage.setItem(appConfig.MENU_ID.M_HOM, true);
      /*
      if (this.userId != "056") {
        sessionStorage.setItem(appConfig.MENU_ID.M_RCV_RDY, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RCV_SCHEDULE_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RCV_SCHEDULE_DATA, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RCV_SCHEDULE_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RCV_SCHEDULE_UPDATE, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_RCV, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RCV_ACTUAL_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RCV_ACTUAL_REPORT, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RCV_ACTUAL_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_ENT, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_ENT_ACTUAL_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_ENT_ACTUAL_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_SHP_RDY, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_SCHEDULE_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_SCHEDULE_DATA, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_SCHEDULE_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_STK_INVEN_ALLOC, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_STK_IVEN_ALLOC_DEL, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_EXT, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_EXT_ACTUAL_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_EXT_ACTUAL_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_SHP, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_ACTUAL_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_ACTUAL_REPORT, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_ACTUAL_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_SRG, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SRG_INVEN_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_MOV, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_MOV_ORDER_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_MOV_ORDER_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_MOV_ACTUAL_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_CNT, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_CNT_PLAN_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_CNT_PLAN_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_CNT_ACTUAL_LIST, true);
        //sessionStorage.setItem(appConfig.MENU_ID.P_CNT_ACUTAL_APPLY, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_BIL, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_BIL_DETAIL_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_BIL_CONTRACT_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_BIL_CONTRACT_UNIT_PRICE_REGISTRATION, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_BIL_ACTUAL_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_BIL_ACTUAL_ADD_UPD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_BIL_APPROVAL, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_BIL_INQUIRY, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_BIL_CUT_OFF_MONTH_REGISTRATION, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_RTN, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RTN_SLIP_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RTN_SLIP_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_MNG, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_MNG_RCV_HISTORY, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_TMP, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_901, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_902, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_903, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_904, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_905, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_906, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_907, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_908, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_909, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_910, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_911, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_912, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TMP_913, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_PRO, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_NOTICE, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_UPDATE, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SUP_USER_MAKER_LINK, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_SORTING_MASTER, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SUP_USER_ITEM_MASTER, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SUP_STOCK_TRANSITION, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_MST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_COM_ITEM_MASTER_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_COM_WAREHOUSE_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_COM_WAREHOUSE_ADD, true);
        //sessionStorage.setItem(appConfig.MENU_ID.P_MST_LOCATION_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_MST_LOCATION_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_RTN_SLIP_APPLY, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_CNT_PLAN_INSERT, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_MST_ITEM_MASTER_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_STC_RECEIVING_STOCK_COMPARE, true);
        sessionStorage.setItem(appConfig.BTN_AUTHORITY_ID.P_STK_INVEN_ALLOC_RESERVE, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_WIDE_TRANSITION, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_SLIP, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_ADDITIONAL_INFOMATION_BY_FORMAT, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_SHP_SLIP_DETAIL, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_STC_STOCK_UPDATE_INSTRUCTION_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_STC_STOCK_UPDATE_INSTRUCTION, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_STC_STOCK_INSTRUCTION_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_WST_DEFECTIVE_DISCARDED_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_WST_DEFECTIVE_DISCARDED_DETAIL, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_INI_DP_MASTER_REGIST_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_INI_WORK_PROCEDURE_MAINTENANCE, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_INI_TEST_ITEM_MAINTENANCE, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_DVP, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_DVP_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_DVP_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_TOD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TOD_ADD, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TOD_LIST, true);
        sessionStorage.setItem(appConfig.MENU_ID.M_TRN, true);
        sessionStorage.setItem(appConfig.MENU_ID.P_TRN_INQUIRY, true);
      }
      */
      sessionStorage.setItem(appConfig.MENU_ID.M_INI_CLIENT_LIST, true);
      sessionStorage.setItem(appConfig.MENU_ID.M_INI_MODULE_SELECT, true);
      sessionStorage.setItem(appConfig.MENU_ID.M_INI_MASTER_REGIST, true);
      sessionStorage.setItem(appConfig.MENU_ID.P_MST_RELATED_ADD, true);
      sessionStorage.setItem(appConfig.MENU_ID.P_MST_RELATED_LIST, true);
      if ((this.userId == sessionStorage.getItem("office_cd")|| this.userId == sessionStorage.getItem("office_cd") + "S") && this.roleId == "chief") {
        //画面遷移
        this.$router.push({ name: appConfig.MENU_ID.M_INI_CLIENT_LIST });
      } else {
        this.$router.push({ name: appConfig.MENU_ID.M_HOM });
      }
    },
    login(val) {
      this.loadingCounter = 1;
      //荷主用チェックボックスがチェックされているかどうかチェック
      if (val == "shipper") {
        // 荷主の場合
        sessionStorage.setItem("shipperKbn", "1");
      } else {
        // 荷主以外の場合
        sessionStorage.setItem("shipperKbn", "0");
      }
      this.$httpClient
        .login(this.userId, this.userPassword)
        .then(() => {
          // 認証情報
          const config = this.$httpClient.createGetApiRequestConfig();
          config.params.authServiceName = ["COGNITO", "AWS"];
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.MST_AUTH_INFO, config)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // console.debug("getAuthInfo() Response", response);
                // 成功
                if (appConfig.RESCOMCODE_SUCCESS == jsonData.resCom.resComCode) {
                  for (var auth of response.data.resIdv.authList) {
                    sessionStorage.setItem(auth.authServiceName + "." + auth.authCd, auth.value);
                  }
                  resolve(response);
                } else {
                  reject();
                }
              })
              .catch((resoleve) => {
                console.error("getAuthInfo() Resoleve", resoleve);
                this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                reject(resoleve);
              })
              .finally(() => {});
          });
        })
        .then(() => {
          this.$httpClient.getApiGatewayCredentials().then(() => {
            this.getUserData()
              .then((response) => {
                this.roleId = response.data.resIdv.userInfo.roleId;
                this.test();
              })
              .catch((ex) => {
                console.error("login Error", ex);
                const message = messsageUtil.getMessage("P-COM-001_001_E");
                this.resultDialog.message = message;
                this.resultDialog.isOpen = true;
                this.resultDialog.title = appConfig.DIALOG.title;
              });
          });
        })
        .catch((ex) => {
          console.error("login Error", ex.message);
          //アカウントがロックされている場合
          if (ex.message == "Password attempts exceeded") {
            this.resultDialog.message = messsageUtil.getMessage("P-COM-001_003_E");
          } else {
            this.resultDialog.message = messsageUtil.getMessage("P-COM-001_001_E");
          }
          this.resultDialog.isOpen = true;
          this.resultDialog.title = appConfig.DIALOG.title;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    getUserData() {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.userId = this.userId;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_USERINFO, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (appConfig.RESCOMCODE_SUCCESS == jsonData.resCom.resComCode) {
              for (var row of jsonData.resIdv.userInfo.attachInfo) {
                if (row.mainFlg == "1" || jsonData.resIdv.userInfo.attachInfo.length == 1) {
                  sessionStorage.setItem("sales_office_sid", row.officeSid);
                  sessionStorage.setItem("comp_sid", row.compSid);
                  sessionStorage.setItem("office_cd", row.officeCd);
                  break;
                }
              }

              sessionStorage.setItem(
                "userInfo.emailAddress",
                jsonData.resIdv.userInfo.emailAddress
              );
              sessionStorage.setItem("userInfo.userName", jsonData.resIdv.userInfo.userName);
              sessionStorage.setItem("userInfo.userId", jsonData.resIdv.userInfo.userId);
              sessionStorage.setItem(
                "userInfo.userNameKana",
                jsonData.resIdv.userInfo.userNameKana
              );
              sessionStorage.setItem(
                "userInfo.userNameShort",
                jsonData.resIdv.userInfo.userNameShort
              );
              sessionStorage.setItem("userInfo.userSid", jsonData.resIdv.userInfo.userSid);
              jsonData.resIdv.userInfo.roleInfo.forEach((row) => {
                sessionStorage.setItem(row.attachResourceKey, row.policy == "1" ? true : false);
              });
              resolve(response);
            } else {
              reject();
            }
          })
          .catch((resoleve) => {
            console.error("getUserData() Resoleve", resoleve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {});
      });
    },
    formValidation() {
      this.$refs.loginForm.validate();
    },
    close() {
      this.resultDialog.isOpen = false;
    },
    getVersion() {
      return appCertification.APPVERSION;
    },
  },
  computed: {
    iconType: function () {
      return this.isChecked ? "eye-slash" : "eye";
    },
    inputType: function () {
      return this.isChecked ? "text" : "password";
    },
  },
};
</script>
<style>
@import "../css/style.css";
</style>
<style lang="scss" scoped>
.eye::after {
  font-family: "FontAwesome";
  content: "\f06e";
}

.eye-slash::after {
  font-family: "FontAwesome";
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -50px;
  margin-right: 10px;
}

::v-deep #login-form {
  padding: 2rem;
  width: 30%;
  min-width: 30rem;
  margin: 10rem auto;
  border: 2px solid #a6a6a6;
  border-radius: 3rem;
}

::v-deep .login-form-login {
  margin-top: 0rem !important;
}

::v-deep .login-form-img {
  border: none !important;
  border-radius: 0 !important;
  margin-bottom: 20px !important;
  box-shadow: none !important;
  margin-top: 70px !important;
  padding: 0rem !important;
}

#company-logo {
  width: 30px;
  height: 30px;
}

#lbl-system-name {
  width: 100%;
}

#input-area {
  margin: auto;
  max-width: 18rem;
  text-align: center;
}

::v-deep #card-title {
  font-size: x-large;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 20px;
}

::v-deep .input-text-fielld {
  margin-top: 0px;

  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        input {
          margin-top: 0px;
          padding: 5px;
          border: 3px solid #d7e4bd !important;
        }
      }
    }
  }
}

::v-deep #btn-login {
  background: #73c7ea;
  color: white;
  font-weight: bold;
  width: 10rem;
}

p {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 14px;
}

p.dialig-text {
  margin-top: 0px;
}
</style>
